import React, { useEffect, useState } from "react";
import styles from "../styles/container/Container.module.scss";
import {
    useConnectWallet
} from '@web3-onboard/react'
import vector from '../icons/Vector.png'
// import rot13 from '../utils/encode.ts'
import {  getMainStreemTokenContract,  getYugaTokenContract} from '../utils/contractHelpers.tsx'
// import documentcopy from '../icons/documentcopy.png'

const Footer = () => {
    const [{ wallet }, connect] = useConnectWallet()
    let account;
    if (wallet)
        account = wallet.accounts[0].address;

    // const BASE_URL = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    // let referlink = account ? `${BASE_URL}/?ref=${rot13(account)}` : `${BASE_URL}/?ref=`
    // let contractLink = `https://bscscan.com/address/${getHobeeTokenContract().address}`
    // let contractAddress = getHobeeTokenContract().address;
    let swapMSM = `https://app.uniswap.org/#/swap?outputCurrency=${getMainStreemTokenContract().address}`
    let swapYuga = `https://app.uniswap.org/#/swap?outputCurrency=${getYugaTokenContract().address}`
    // let swapHeth = `https://pancakeswap.finance/swap?outputCurrency=${getHethTokenContract().address}`

    let dashboard = `https://themainstreem.com/`

    return (
        <div>
            <div className={styles.footer}>
               
                <section className={styles.contractAddress}>
                    <a className={styles.dashboardText} href={dashboard} target="_blank">
                        <p>To the Dashboard</p>
                    </a>
                </section>

                <section className={styles.swapPancake}>
                    <a className={styles.swapPancakeText} href={swapMSM} target="_blank">
                        <p>Buy MSM Token</p>
                    </a>
                    <a className={styles.swapPancakeText} href={swapYuga} target="_blank">
                        <p>Buy Yuga Token</p>
                    </a>
                    {/* <a className={styles.swapPancakeText} href={swapHeth} target="_blank">
                        <p>Buy HETH</p>
                    </a>
                    <a className={styles.swapPancakeText} href={swapHobee} target="_blank">
                        <p>Buy HOBEE</p>
                    </a> */}
                </section>
                {/* </>
                } */}
            </div>
            <div className={styles.endSection}>
                <p>All rights reserved © 2023</p>
                <p>The MainStream.com</p>
            </div>
        </div>
    );
};

export default Footer;
