import React from "react";
import {
    useConnectWallet,

} from '@web3-onboard/react'
import styles from "../styles/container/Container.module.scss";
import mainLogo from "../icons/mainstreemIcons.png";



const Header = () => {
    const [{ wallet }, connect] = useConnectWallet()

    return (
        <>
            <div className={styles.HeaderContainer}>
                <div className={styles.HeaderContainer}>
                    <section className={styles.BalanceSection}>
                        <img src={mainLogo} alt="logo" />
                    </section>
                    <section className={styles.ButtonContainer}>
                        
                        {!wallet ?
                            <button
                                className={styles.ConnectButton}
                                onClick={async () => {
                                    const walletsConnected = await connect()
                                    console.log('connected wallets: ', walletsConnected)
                                }}
                            >
                                Select a Wallet
                            </button>
                            :
                            <section className={styles.ConnectWalletSection}></section>
                        }
                    </section>
                </div>

            </div>
            <div className={styles.HeaderLine}></div>
        </>
    );
};

export default Header;
