import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import {
  initWeb3Onboard,
  ethMainnetGasBlockPrices,
  infuraRPC
} from '../services'
import {
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets
} from '@web3-onboard/react'
import '../App.css'
import { getStakingContract, getMainStreemTokenContract, MainStreemAddress, YugaAddress, StakingContractAddress } from '../utils/contractHelpers.tsx'
import StakingContractAbi from '../config/Vault.json'
import TokenContractAbi from '../config/BSCContract.json'
import "../styles/StakingContainer.css";
import Input from "../components/Input.tsx";
import { Spinner } from '../container/Spinner.tsx';

import Header from "../container/Header.jsx";
import Footer from "../container/Footer.jsx";
import logo from '../icons/AupdatedLOGO.png'

let provider;


const App = () => {
  const [{ wallet }, connect] = useConnectWallet()
  const [{ connectedChain, settingChain }, setChain] = useSetChain()
  const [notifications] = useNotifications()
  const connectedWallets = useWallets()

  const [web3Onboard, setWeb3Onboard] = useState(null)

  const [bnGasPrices, setBNGasPrices] = useState('')
  const [rpcInfuraGasPrices, setRPCInfuraGasPrices] = useState('')


  const [tokenInfo, setTokenInfo] = useState(0);
  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
  }, [])

  useEffect(() => {
    console.log(notifications)
  }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )

    // Check for Magic Wallet user session
    if (connectedWalletsLabelArray.includes('Magic Wallet')) {
      const [magicWalletProvider] = connectedWallets.filter(
        provider => provider.label === 'Magic Wallet'
      )
      async function setMagicUser() {
        try {
          const { email } =
            await magicWalletProvider.instance.user.getMetadata()
          const magicUserEmail = localStorage.getItem('magicUserEmail')
          if (!magicUserEmail || magicUserEmail !== email)
            localStorage.setItem('magicUserEmail', email)
        } catch (err) {
          throw err
        }
      }
      setMagicUser()
    }
  }, [connectedWallets, wallet])

  useEffect(() => {
    if (!wallet?.provider) {
      provider = null
    } else {
      provider = new ethers.providers.Web3Provider(wallet.provider, 'any')

    }
  }, [wallet])

  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        const walletConnected = await connect({
          autoSelect: previouslyConnectedWallets[0]
        })
        console.log('connected wallets: ', walletConnected)
      }
      setWalletFromLocalStorage()
    }
  }, [connect])

  useEffect(() => {
    ethMainnetGasBlockPrices.subscribe(estimates => {
      setBNGasPrices(estimates[0].blockPrices[0].estimatedPrices)
    })
  }, [])

  useEffect(() => {
    async function getEtherGasFromRPC() {
      const customHttpProvider = new ethers.providers.JsonRpcProvider(infuraRPC)
      const fee = await customHttpProvider.getFeeData()

      const cleanFees = {
        price: ethers.utils.formatUnits(fee.gasPrice, 'gwei'),
        maxPriorityFeePerGas: ethers.utils.formatUnits(
          fee.maxPriorityFeePerGas,
          'gwei'
        ),
        maxFeePerGas: ethers.utils.formatUnits(fee.maxFeePerGas, 'gwei')
      }
      setRPCInfuraGasPrices(cleanFees)
    }
    getEtherGasFromRPC()
  }, [bnGasPrices])


  let account;
  if (wallet) {
    account = wallet?.accounts[0]?.address;
  }

  const { ethereum } = window;
  const switchRequestETH = () => {
    return window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x5" }], //testnet
      // params: [{ chainId: "0x1" }], //mainnet
    });
  };
  React.useEffect(() => {
    const switchChain = async () => {
      if (window.ethereum) {
        try {
          await switchRequestETH();
        } catch (error) {
        }
      }
    };
    switchChain();
  }, [ethereum]);

  const onMSMAllowance = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      const contractInstance = new ethers.Contract(MainStreemAddress, TokenContractAbi, signer);
      const allowance = await contractInstance.approve(StakingContractAddress, "999999999999999999999999999");
      await allowance.wait();
      setTokenInfo(1);
    } catch (err) {
    }
  };

  const onMSMDeposit = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      console.log(signer, 'debug->signer')
      const contractInstance = new ethers.Contract(StakingContractAddress, StakingContractAbi, signer);
      const deposit = await contractInstance.deposit(`0x${(Number(MSMValue) * (10 ** 18)).toString(16)}`, 1);
      await deposit.wait();
      setTokenInfo(1);
    } catch (err) {
    }
  };

  const onMSMWithdraw = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      const contractInstance = new ethers.Contract(StakingContractAddress, StakingContractAbi, signer);
      const withdraw = await contractInstance.withdraw(1);
      await withdraw.wait();
      setTokenInfo(1);
    } catch (err) {
    }
  };

  const [MSMValue, setMSMValue] = useState('');
  const setMSMMaxValue = async () => {
    let value;
    value = (MSMBalance * 0.9999999999 / 10 ** 18).toString();
    setMSMValue(value);
  };




  const onYugaAllowance = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      const contractInstance = new ethers.Contract(YugaAddress, TokenContractAbi, signer);
      const allowance = await contractInstance.approve(StakingContractAddress, "999999999999999999999999999");
      await allowance.wait();
      setTokenInfo(1);
    } catch (err) {
    }
  };

  const onYugaDeposit = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      const contractInstance = new ethers.Contract(StakingContractAddress, StakingContractAbi, signer);
      const deposit = await contractInstance.deposit(`0x${(Number(YugaValue) * (10 ** 18)).toString(16)}`, 2);
      await deposit.wait();
      setTokenInfo(1);
    } catch (err) {
    }
  };

  const onYugaWithdraw = async () => {
    try {
      const signer = provider.getUncheckedSigner()
      const contractInstance = new ethers.Contract(StakingContractAddress, StakingContractAbi, signer);
      const withdraw = await contractInstance.withdraw(2);
      await withdraw.wait();
      setTokenInfo(1);
    } catch (err) {
    }
  };

  const [YugaValue, setYugaValue] = useState('');
  const setYugaMaxValue = async () => {
    let value;
    value = (YugaBalance * 0.9999999999 / 10 ** 18).toString();
    setYugaValue(value);
  };


  const [totalMSMSupply, setTotalMSMSupply] = useState("");
  const [depositMSMAmount, setDepositMSMAmount] = useState("");
  const [MSMBalance, setMSMBalance] = useState("");
  const [MSMAllowance, setMSMAllowance] = useState("");
  const [totalYugaSupply, setTotalYugaSupply] = useState("");
  const [depositYugaAmount, setDepositYugaAmount] = useState("");
  const [YugaBalance, setYugaBalance] = useState("");
  const [YugaAllowance, setYugaAllowance] = useState("");

  useEffect(() => {
    const totalInfoState = async () => {
      const StakingContract = getStakingContract()
      try {
        if (wallet) {
          const depositInfos = await StakingContract.depositInfo(account, StakingContractAddress);
          const totalInfos = await StakingContract.totalInfo(account);
          const depositMSMAmount = depositInfos[0];
          const depositYugaAmount = depositInfos[1];
          const _MSMTokenAllowance = depositInfos[2];
          const _YugaTokenAllowance = depositInfos[3];
          const totalMSMSupply = totalInfos[0];
          const totalYugaSupply = totalInfos[1];
          const _MSMTokenBalance = totalInfos[2];
          const _YugaTokenBalance = totalInfos[3];
          setTotalMSMSupply(Number(totalMSMSupply));
          setDepositMSMAmount(Number(depositMSMAmount));
          setMSMBalance(Number(_MSMTokenBalance));
          setMSMAllowance(Number(_MSMTokenAllowance));
          setTotalYugaSupply(Number(totalYugaSupply));
          setDepositYugaAmount(Number(depositYugaAmount));
          setYugaBalance(Number(_YugaTokenBalance));
          setYugaAllowance(Number(_YugaTokenAllowance));
        }
      } catch (e) {
        console.error(e)
      }
    }
    if (account) {
      totalInfoState()

    }
  }, [account])
  console.log(connectedChain, totalMSMSupply)

  return (
    <main>
      < Header />
      <div className="GlobalContainer">
        {account ?
          <div className="MainDashboard">
            <section className="ContactBox">
              {connectedChain.id === "0x5" && totalMSMSupply !== "" ?
                <>
                  <section className="ContractContainer">
                    <section className='DashboardSubContainer'>
                      <section className="DashboardContainer">
                        <p className="ContractContentTextHeader">APR : </p>
                        <p className="ContractContentText">60%</p>
                      </section>
                      <section className="DashboardContainer">
                        <p className="ContractContentTextHeader">Total Deposited Amount : </p>
                        <p className="ContractContentText">{Number(totalMSMSupply) > 0 ? (Number(totalMSMSupply) / (10 ** 18)).toFixed(3) : 0} MSM</p>
                      </section>
                      <section className="DashboardContainer">
                        <p className="ContractContentTextHeader">Your Deposited Amount : </p>
                        <p className="ContractContentText">{Number(depositMSMAmount) > 0 ? (Number(depositMSMAmount) / (10 ** 18)).toFixed(3) : 0} MSM</p>
                      </section>
                    </section>
                    <section className="DepositBoxHeader">
                      <p className="ContractContentTextTitle">Deposit</p>
                    </section>

                    {MSMAllowance > 0 ?
                      <>
                        <section className="DashboardContainer">
                          <p className="ContractContentTextHeader"> Your available Amount : </p>
                          <p className="ContractContentText">{Number(MSMBalance) > 0 ? ((Number(MSMBalance)) / (10 ** 18)).toFixed(3) : 0} MSM</p>
                        </section>
                        <section className='inputPanel'>
                          <section className='inputPanelHeader'>
                            <Input
                              placeholder="Enter amount"
                              label=""
                              type="number"
                              changeValue={setMSMValue}
                              value={MSMValue}
                            />
                          </section>
                          <p onClick={setMSMMaxValue} className="MaxButton">Max</p>
                        </section>
                        <section className="StakingBox">
                          {wallet?.accounts[0]?.balance !== null ?
                            <button disabled={false} onClick={onMSMDeposit} className="StakingButton">Staking</button>
                            : <></>
                          }
                        </section></>
                      :
                      <section className="StakingBox">
                        <p className='ContractExplain'>Please approve MSM Token first</p>
                        <button disabled={false} onClick={onMSMAllowance} className="StakingButton">Allowance MSM</button>
                      </section>
                    }
                    <div className="bnbPoolContentLine"></div>
                    <section className="DepositBoxHeader">
                      <p className="ContractContentTextTitle">Withdraw</p>
                    </section>
                    <section className="DashboardContainer">
                      <p className="ContractContentTextHeader">Your Daily Reward Amount : </p>
                      <p className="ContractContentText">{Number(depositMSMAmount) > 0 ? ((Number(depositMSMAmount) * 60 / 365) / (10 ** 20)).toFixed(3) : 0} MSM</p>
                    </section>
                    <section className="DashboardContainer">
                      <p className="ContractContentTextHeader">Withdrawable Amount : </p>
                      <p className="ContractContentText">{Number(depositMSMAmount) > 0 ? (Number(depositMSMAmount) / (10 ** 18)).toFixed(3) : 0} MSM</p>
                    </section>
                    <section className="StakingBox">
                      {wallet?.accounts[0]?.balance !== null ?
                        <button onClick={onMSMWithdraw} className="StakingButton">Withdraw</button>
                        : <></>
                      }
                    </section>
                  </section>

                  <section className="ContractContainer">
                    <section className='DashboardSubContainer'>
                      <section className="DashboardContainer">
                        <p className="ContractContentTextHeader">APR : </p>
                        <p className="ContractContentText">60%</p>
                      </section>
                      <section className="DashboardContainer">
                        <p className="ContractContentTextHeader">Total Deposited Amount : </p>
                        <p className="ContractContentText">{Number(totalYugaSupply) > 0 ? (Number(totalYugaSupply) / (10 ** 18)).toFixed(3) : 0} Yuga</p>
                      </section>
                      <section className="DashboardContainer">
                        <p className="ContractContentTextHeader">Your Deposited Amount : </p>
                        <p className="ContractContentText">{Number(depositYugaAmount) > 0 ? (Number(depositYugaAmount) / (10 ** 18)).toFixed(3) : 0} Yuga</p>
                      </section>
                    </section>
                    <section className="DepositBoxHeader">
                      <p className="ContractContentTextTitle">Deposit</p>
                    </section>

                    {YugaAllowance > 0 ?
                      <>
                        <section className="DashboardContainer">
                          <p className="ContractContentTextHeader"> Your available Amount : </p>
                          <p className="ContractContentText">{Number(YugaBalance) > 0 ? ((Number(YugaBalance)) / (10 ** 18)).toFixed(3) : 0} Yuga</p>
                        </section>
                        <section className='inputPanel'>
                          <section className='inputPanelHeader'>
                            <Input
                              placeholder="Enter amount"
                              label=""
                              type="number"
                              changeValue={setYugaValue}
                              value={YugaValue}
                            />
                          </section>
                          <p onClick={setYugaMaxValue} className="MaxButton">Max</p>
                        </section>
                        <section className="StakingBox">
                          {wallet?.accounts[0]?.balance !== null ?
                            <button disabled={false} onClick={onYugaDeposit} className="StakingButton">Staking</button>
                            : <></>
                          }
                        </section></>
                      :
                      <section className="StakingBox">
                        <p className='ContractExplain'>Please approve Yuga Token first</p>
                        <button disabled={false} onClick={onYugaAllowance} className="StakingButton">Allowance Yuga</button>
                      </section>
                    }
                    <div className="bnbPoolContentLine"></div>
                    <section className="DepositBoxHeader">
                      <p className="ContractContentTextTitle">Withdraw</p>
                    </section>
                    <section className="DashboardContainer">
                      <p className="ContractContentTextHeader">Your Daily Reward Amount : </p>
                      <p className="ContractContentText">{Number(depositYugaAmount) > 0 ? ((Number(depositYugaAmount) * 60 / 365) / (10 ** 20)).toFixed(3) : 0} Yuga</p>
                    </section>
                    <section className="DashboardContainer">
                      <p className="ContractContentTextHeader">Withdrawable Amount : </p>
                      <p className="ContractContentText">{Number(depositYugaAmount) > 0 ? (Number(depositYugaAmount) / (10 ** 18)).toFixed(3) : 0} Yuga</p>
                    </section>
                    <section className="StakingBox">
                      {wallet?.accounts[0]?.balance !== null ?
                        <button onClick={onYugaWithdraw} className="StakingButton">Withdraw</button>
                        : <></>
                      }
                    </section>
                  </section>
                </>
                :
                <section className="ConnectWalletBox">
                  <p className="ContractPoolAmount">Loading Data...</p>
                  <section className="SpinnerBox">
                    <Spinner />
                  </section>
                </section>
              }
            </section>
          </div>
          :
          <section className="ConnectWalletBox">
            <p className="ContractPoolAmount">Please connect wallet first</p>
            <div className="ConnectWalletBoxButton">
            </div>
          </section>
        }
      </div>
      {/* <div>
        <img src={logo}  alt="logo"></img>
      </div> */}
      <Footer />
    </main >
  )
}

export default App
