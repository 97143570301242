import { ethers } from 'ethers'
import ETHContractAbi from '../config/ETHContract.json'
import StakingAbi from '../config/Vault.json'

const ETH_RPC_URL = 'https://goerli.infura.io/v3/201919f24d524981b4931e640ba59233'

const simpleETHRpcProvider = new ethers.providers.JsonRpcProvider(ETH_RPC_URL)

const getETHContract = (abi: any, address: string, signer?: ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleETHRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}
export const StakingContractAddress = "0xc4ac026E5A19C93A84DfF9c0dceacFE3B8d38405";
export const MainStreemAddress = "0xc9abb1545c70beb7d4002ba91420c77e6fcd159a";
export const YugaAddress = "0x63b8aca19f4873eec945c38c4e4fd1836dde5ad8";
// export const StakingContractAddress = "0x82900c8Cfa860C795F9c7d5A9bDd8e3d5788a3F9";
// export const MainStreemAddress = "0x7d623c06F9ceEFed04c2278b578DA512c3A01f55";

export const getMainStreemTokenContract = (signer?: ethers.providers.Provider) => {
  return getETHContract(ETHContractAbi, MainStreemAddress, signer)
}

export const getYugaTokenContract = (signer?: ethers.providers.Provider) => {
  return getETHContract(ETHContractAbi, YugaAddress, signer)
}

export const getStakingContract = (signer?: ethers.providers.Provider) => {
  return getETHContract(StakingAbi, StakingContractAddress, signer)
}