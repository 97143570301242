import React from "react";
import styles from "./App.module.scss";

// import Dashboard from "./container/DashboardContainer";
// import Backoffice from "./container/BackofficeContainer";
// import Support from "./container/SupportContainer";
// import AdminContainer from "./container/AdminContainer";
import StakingContainer from "./container/StakingContainer";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params';

// import Header from "./container/Header";



const App = () => {
  return (
    <Router>
      <QueryParamProvider>
        {/* <Header /> */}
        <div className={styles.App}>
          <Routes>
            <Route path="" element={<StakingContainer />} />
            {/* <Route path="staking" element={<StakingContainer />} /> */}
            {/* <Route path="backoffice" element={<Backoffice />} />
            <Route path="support" element={<Support />} />
            <Route path="admin" element={<AdminContainer />} /> */}
          </Routes>
        </div>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
